
.rb-particles {
  background-color: transparent;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /*border: 1px solid red;*/
}
.rb-particles > .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1111;
    /*background-image: url('../../assets/bg.jpg');*/
}
.rb-particles-stage {
    opacity: 0.7;
}
